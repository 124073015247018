export const getTypoCommonStyles = ({
  theme,
  color = 'dark',
  noPointers = false,
  cursor = false,
  nowrap = false,
  $hoverColor = null,
  td = '',
  fw = '400',
  lh = null,
  fs = null,
  ff = null,
  ls = null,
  caps = false,
  size = 'normal',
  display = '',
  maxWidth,
  $opacity,
  $width,
  $ws,
  $tt,
  $minHeight,
}) => {
  const componentTheme = theme.components.typo
  const sizes = componentTheme.sizes[size] || componentTheme.sizes.normal
  const xsSizes = componentTheme.sizes[size === 'big' ? 'normal' : 'small'] || componentTheme.sizes.normal
  const letterSpacing = ls || sizes.ls || componentTheme.styles.ls
  const lineHeight = lh || sizes.lh || componentTheme.styles.lh

  return `
    display: ${display || ''};
    width: ${$width || ''};
    color: ${theme.colors[color] || color};
    text-transform: ${$tt || ''};
    cursor: ${cursor || ''};
    pointer-events: ${noPointers ? 'none' : ''};
    white-space: ${nowrap ? 'nowrap' : $ws || ''};
    text-decoration: ${caps ? 'uppercase' : td};
    transition: color 0.3s ease-in-out;
    font-family: ${ff ? theme.ff[ff] : theme.ff.main};
    font-size: ${fs || sizes.fs};
    line-height: ${lineHeight || theme.lh.normal};
    font-weight: ${theme.fw[fw] || fw};
    letter-spacing: ${letterSpacing || theme.ls.main};
    max-width: ${maxWidth || ''};
    opacity: ${$opacity || ''};
    min-height: ${$minHeight || ''};
    transition: ${$hoverColor ? 'color 0.3s ease-in-out' : ''};

    @media (max-width: 360px) {
      font-size: ${xsSizes.fs || fs};
      line-height: ${xsSizes.lh || lh};
    }

    &.--no-select {
      user-select: none;
      cursor: inherit;
    }

    &.--break {
      word-break: break-all;
      cursor: inherit;
    }

    &.--ta {
      &-left {
        text-align: left;
      }

      &-center {
        text-align: center;
      }

      &-right {
        text-align: right;
      }
    }

    &.--active,
    &:hover {
      color: ${$hoverColor ? theme.colors[$hoverColor] || $hoverColor : ''};
    }
  `
}
