import {
  UserGenderModel, UserRolesNamesTypes, UserRolesTypes
} from 'constants/UserTypes'
import { setNotification } from 'redux/System/SystemActions'
import store from 'redux/store/configureStore'
import { formatDate, getPersonAge } from './Date'
import { copyTextToClipboard } from './Navigator'
import { numeralizeAge } from './Numeralize'

// Cтарая версия getUserName, нужно для совместимости
export const getUserFullName = (user = null, isMiddleName = false, join = ' ') => {
  if (!user) {
    return ''
  }

  const nameArray = []

  if (user.last_name) {
    nameArray.push(user.last_name)
  }

  if (user.first_name) {
    nameArray.push(user.first_name)
  }

  if (isMiddleName && user.middle_name) {
    nameArray.push(user.middle_name)
  }

  return nameArray.join(join)
}

export const getUserName = (user = null, isMiddleName = true, isLastName = true, isFirstName = true, join = ' ') => {
  if (!user) {
    return ''
  }

  const nameArray = []

  if (isLastName && user.last_name) {
    nameArray.push(user.last_name)
  }

  if (isFirstName && user.first_name) {
    nameArray.push(user.first_name)
  }

  if (isMiddleName && user.middle_name) {
    nameArray.push(user.middle_name)
  }

  return nameArray.join(join)
}

export const getUserRoleTitle = (role) => (typeof role === 'string'
  ? UserRolesNamesTypes[role.toLowerCase()] || ''
  : '')

export const getUserSurnameWithAbr = (user = null) => {
  if (!user) {
    return ''
  }

  let str = ''

  if (user.last_name) {
    str += user.last_name
  }

  if (user.first_name) {
    str += ` ${user.first_name.slice(0, 1)}.`
  }

  if (user.middle_name) {
    str += ` ${user.middle_name.slice(0, 1)}.`
  }

  return str
}

export const getUserAvatarAbr = (user = null) => {
  if (!user) {
    return ''
  }

  let str = ''

  if (user.last_name) {
    str += user.last_name.slice(0, 1)
  }

  if (user.first_name) {
    str += user.first_name.slice(0, 1)
  }

  return str
}

export const getMaskedPhone = (phone = '') => {
  if (!phone) {
    return ''
  }

  const p = String(phone).replace('+', '')

  return `+${p[0] || ''} (${p[1] || ''}${p[2] || ''}${p[3] || ''}) ${p[4] || ''}${p[5] || ''}${p[6] || ''}-${p[7] || ''}${p[8] || ''}-${p[9] || ''}${p[10] || ''}`
}

export const handleCopyPhones = (value = '', phones = null) => {
  const { dispatch } = store

  if (phones && Array.isArray(phones)) {
    phones.find((p) => {
      if (p.id === value) {
        copyTextToClipboard(p.title)
        return true
      }

      return false
    })
  }
  else {
    copyTextToClipboard(value)
  }

  if (value && dispatch) {
    dispatch(setNotification({ message: 'Телефон скопирован.' }))
  }
}

export function getZoom(values) {
  let zoom = 0

  if (values.country) zoom += 4
  if (values.city) zoom += 4
  if (values.address) zoom += 4
  if (values.house_number) zoom += 4

  return zoom
}

export function getFullAddress(location) {
  let str = ''

  if (!location) {
    return str
  }

  if (location.country) str += `${location.country}`
  if (location.city) str += ` ${location.city}`
  if (location.street) str += ` ${location.street}`
  if (location.house_number) str += ` ${location.house_number}`
  if (location.corps) str += ` ${location.corps}`

  return str
}

export const getPersonAgeTitle = (date) => {
  const age = getPersonAge(date)
  return `${age} ${numeralizeAge(age)}`
}

export const getNormalizedPhone = (phone, phonePlaceholder = 'Телефон не указан') => {
  if (phone) {
    return getMaskedPhone(phone) || phonePlaceholder
  }

  return phonePlaceholder
}

export const getUserGenderTitle = (gender, isShort = false) => {
  if (!gender) {
    return ''
  }

  const titleName = isShort ? 'titleShort' : 'title'

  return (UserGenderModel[gender] || { [titleName]: null })[titleName]
}

export const getUserGenderAndAgeTitle = (gender, birthday) => {
  let str = ''

  if (gender) {
    const genderObj = UserGenderModel[gender] || {}

    if (genderObj.title) {
      str += `${genderObj.title}${birthday ? ', ' : ''}`
    }
  }

  if (birthday) {
    str += `${getPersonAgeTitle(birthday)} • `
    str += formatDate(birthday)
  }

  return str
}

export const getUserToken = (token) => {
  const fulToken = 'token '
  return token ? fulToken.concat(token) : fulToken
}
export const getRole = (role) => ({
  isAdmin: role === UserRolesTypes.CONSULTANT,
  isDoctor: role === UserRolesTypes.DOCTOR,
  isCoach: role === UserRolesTypes.COACH,
  isPatient: role === UserRolesTypes.PATIENT,
  isSupport: role === UserRolesTypes.SUPPORT,
  isClinicSupervisor: role === UserRolesTypes.CLINIC_SUPERVISOR,
})
