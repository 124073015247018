import {
  format,
  isThisYear,
  parseISO,
  isToday,
  parse,
  isSameDay,
  isSameMonth,
  isSameYear,
  compareDesc,
  subWeeks,
  subMonths,
  compareAsc,
  getMonth,
  getDaysInMonth,
  startOfMonth,
  addMonths,
  addDays,
  isYesterday,
  differenceInYears,
} from 'date-fns'
import ruLocale from 'date-fns/locale/ru'
import { dateUnits } from 'constants/UnitsTypes'
import { numeralizeTime, numeralizeTypes } from './Numeralize'

const mainDateFormatterShort = 'dd MMMM'
const mainDateFormatter = `${mainDateFormatterShort} yyyy`
export const DATE_FORMAT_MAIN = mainDateFormatter
export const DATE_FORMAT_MAIN_SHORT = 'd MMMM yyyy'
export const DATE_FORMAT_WITH_TIME = 'dd.MM.yyyy в HH:mm'
export const DATE_FORMAT_WITH_TIME_LONG = 'dd MMMM yyyy в HH:mm'
export const DATE_FORMAT_DELETED = 'dd.MM.yyyy, HH:mm'
export const DATE_FORMAT_DATEPICKER = 'yyyy-MM-dd'

// export const formatDateDayAndMonth = (date) => format(new Date(date), 'd MMMM', { locale: ruLocale })

// export const formatDateDayMonthYear = (date) => format(new Date(date), 'd MMMM, yyyy', { locale: ruLocale })

// Форматирование даты в тот, что принимает сервер
export function convertDateFormatForServer(inputDate, inputFormat = 'dd.MM.yyyy') {
  const parsedDate = parse(inputDate, inputFormat, new Date())
  const formattedDate = format(parsedDate, 'yyyy-MM-dd')

  return formattedDate
}

export const formatDate = (date, dateFormat = 'dd.MM.yyyy') => {
  if (date) {
    return format(new Date(date), dateFormat, { locale: ruLocale })
  }

  return ''
}

export const getDateMonth = (date) => (date ? getMonth(date, { locale: ruLocale }) : null)

export const getMonthDays = (month) => (getDaysInMonth(month, { locale: ruLocale }))

export const addMonthCount = (date, num) => (addMonths(date, num))

export const addDaysCount = (date, num) => (addDays(date, num))

export const getStartOfMonth = (month) => (startOfMonth(month, { locale: ruLocale }))

export const formatDateExpired = (date, dateFormat = mainDateFormatter) => (date
  ? `до ${format(new Date(date), dateFormat, { locale: ruLocale })}`
  : null)

export const formatDateOrToday = (date, dateFormat = 'dd.MM.yyyy') => {
  const today = isToday(parseISO(date))
  const dateFormatted = new Date(date)

  if (today) return 'cегодня'
  return format(dateFormatted, dateFormat, { locale: ruLocale })
}

export const formatDateOrTodayDynamic = (date, delimiter = ', ') => {
  if (!date) return date

  const dateFormatted = new Date(date)
  const dateIso = parseISO(date)
  const isThisYearDate = isThisYear(dateFormatted)
  const time = formatDate(date, 'HH:mm')

  if (isThisYearDate) {
    const today = isToday(dateIso)
    const yesterday = isYesterday(dateIso)

    if (today) return `Сегодня${delimiter}${time}`
    if (yesterday) return `Вчера${delimiter}${time}`
  }

  const formatter = isThisYearDate ? mainDateFormatterShort : mainDateFormatter

  return `${formatDate(date, formatter)}, ${time}`
}

export const formatDateLastAction = (date) => {
  if (!date) return date

  const dateFormatted = new Date(date)
  const isThisYearDate = isThisYear(dateFormatted)

  if (isThisYearDate) {
    const today = isToday(dateFormatted)
    const yesterday = isYesterday(dateFormatted)

    if (today) return 'Сегодня'
    if (yesterday) return 'Вчера'
  }

  const formatter = isThisYearDate ? 'dd MMM' : 'dd MMM YYYY'

  return formatDate(date, formatter)
}

export const formatDateOrTodayTime = (date, dateFormat = 'dd.MM.yyyy') => {
  const today = isToday(date)
  const dateFormatted = new Date(date)

  if (today) return formatDate(dateFormatted, 'HH:mm')
  return format(dateFormatted, dateFormat, { locale: ruLocale })
}

export const compareDatesDesc = (dateLeft, dateRight) => compareDesc(new Date(dateLeft), new Date(dateRight))
export const compareDatesAsc = (dateLeft, dateRight) => compareAsc(new Date(dateLeft), new Date(dateRight))

export const formatDateToQuery = (date, dateFormat = 'yyyy-MM-dd') => formatDate(date, dateFormat)

export const formatTime = (time, formater = 'HH:mm') => format(parse(time, `HH:mm:ss`, new Date()), formater)

// export const dateIsExpired = (date) => (new Date().getTime() > new Date(date).getTime())

export const isInOneDay = (first, second) => (first && second ? isSameDay(new Date(first), new Date(second)) : false)
export const isInOneMonth = (first, second) => isSameMonth(new Date(first), new Date(second))
export const isInOneYear = (first, second) => isSameYear(new Date(first), new Date(second))

// export const checkIsToday = (date) => isToday(new Date(date))

export const checkIsThisYear = (date) => isThisYear(new Date(date))

export const getGroupDate = (date, isHideCurrentYear = false) => {
  if (!date) {
    return ''
  }

  const parsedDate = parseISO(date)

  if (isHideCurrentYear) {
    const isThisYear = checkIsThisYear(parsedDate)
    return formatDateOrToday(parsedDate, isThisYear ? 'd MMMM' : mainDateFormatter)
  }

  return formatDateOrToday(parsedDate, mainDateFormatter)
}

export const getTaskDurationDate = (start, end) => {
  const defaultFormatEnd = 'dd MMMM yyyy'
  let defaultFormatStart = 'dd'

  if (start && end) {
    if (!isInOneMonth(start, end)) {
      defaultFormatStart += ' MMMM'
    }

    if (!isInOneYear(start, end)) {
      defaultFormatStart += ' yyyy'
    }

    return `${formatDate(new Date(start), defaultFormatStart)}-${formatDate(new Date(end), defaultFormatEnd)}`
  }

  if (end) {
    return formatDate(new Date(end), defaultFormatEnd)
  }

  return 'Нет сроков'
}

// export const checkIsTomorrow = (date) => isTomorrow(new Date(date))

// export const isTodayOrTommorow = (date) => {
//   if (checkIsToday(date)) {
//     return 'Сегодня'
//   } if (checkIsTomorrow(date)) {
//     return 'Завтра'
//   }

//   return false
// }

// export const getDaysDiff = (first, second) => Math.round((second - first) / (1000 * 60 * 60 * 24))

export const getRelatedDateProps = (date) => {
  const dateMs = Date.now() - new Date(date).getTime()
  let dateValue
  let dateUnit

  if (dateMs < 1000 * 60) {
    dateUnit = dateUnits.SECOND
    dateValue = dateMs / 1000
  }
  else if (dateMs < 1000 * 60 * 60) {
    dateUnit = dateUnits.MINUTE
    dateValue = dateMs / 1000 / 60
  }
  else if (dateMs < 1000 * 60 * 60 * 24) {
    dateUnit = dateUnits.HOUR
    dateValue = dateMs / 1000 / 60 / 60
  }
  else if (dateMs < 1000 * 60 * 60 * 24 * 30) {
    dateUnit = dateUnits.DAY
    dateValue = dateMs / 1000 / 60 / 60 / 24
  }
  else if (dateMs < 1000 * 60 * 60 * 24 * 30 * 12) {
    dateUnit = dateUnits.MONTH
    dateValue = dateMs / 1000 / 60 / 60 / 24 / 30
  }
  else {
    dateUnit = dateUnits.YEAR
    dateValue = dateMs / 1000 / 60 / 60 / 60 / 24 / 30 / 12
  }

  return {
    unit: dateUnit,
    value: -dateValue.toFixed(0)
  }
}

export const dateRemoveWeeks = (date, num = 1) => (date ? subWeeks(new Date(date), num) : '')

export const dateRemoveMonths = (date, num = 1) => (date ? subMonths(new Date(date), num) : '')

export const getPersonAge = (date = '') => (date
  // ? new Date().getFullYear() - new Date(date).getFullYear()
  ? differenceInYears(new Date(), new Date(date))
  : '')

export const getTimeFromSeconds = (milisec, isHours = false) => {
  const secNum = parseInt(milisec, 10) // don't forget the second param
  let hours = Math.floor(secNum / 3600)
  let minutes = Math.floor((secNum - (hours * 3600)) / 60)
  let seconds = secNum - (hours * 3600) - (minutes * 60)

  if (hours < 10) {
    hours = `0${hours}`
  }
  if (minutes < 10) {
    minutes = `0${minutes}`
  }
  if (seconds < 10) {
    seconds = `0${seconds}`
  }
  return `${isHours ? `${hours}:` : ''}${minutes}:${seconds}`
}

export const getTimeFromDate = (date, isHours) => {
  const timeStr = date.split('T')[1] || '00:00:00'
  const timeArr = (timeStr || '').split(':')

  if (!isHours) {
    return `${timeArr[1]}:${timeArr[2]}`
  }

  return `${timeArr[0]}:${timeArr[1]}`
}

export const sortByDateDesc = (items = [], propName = 'date') => items.sort((a, b) => compareDatesDesc(a[propName], b[propName]))

export const normalizeFullTime = (value) => {
  let timeStr = value

  if (!timeStr) {
    return ''
  }

  if (typeof timeStr === 'number') {
    timeStr = getTimeFromSeconds(timeStr, true)
  }

  const [ h, m, s ] = timeStr.split(':')

  let title = ''
  let time = ''

  if (h > 0) {
    time = parseInt(h)
    title += `${time} ${numeralizeTime(time, numeralizeTypes.HOURS)} `
  }
  if (m > 0) {
    time = parseInt(m)
    title += `${time} ${numeralizeTime(time, numeralizeTypes.MINUTES)} `
  }
  if (s > 0) {
    time = parseInt(s)
    title += `${time} ${numeralizeTime(time, numeralizeTypes.SECONDS)} `
  }

  return title.trim()
}
