import s from 'styled-components'
import Text from 'components/typo/Text'

const FormErrorContainer = s.div`
  margin-top: 6px;
  font-size: 12px;
  text-align: left;

  ${({ absolute }) => (absolute ? `
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: 4px;
  ` : '')}
`

const FormError = ({ title = '', ...otherProps }) => (
  <FormErrorContainer {...otherProps}>
    <Text size="small" color="red">
      {title}
    </Text>
  </FormErrorContainer>
)

export default FormError
