export const messageTypes = {
  STAR: 'star',
  DEFAULT: 'default',
  STOP: 'stop',
  CHECK: 'check',
  MESSAGE: 'message',
  CHAT_MESSAGE: 'chat-message',
  USER_ADD_FILE: 'PatientFile',
  RESULT_BIND: 'ResultBind',
  PATIENT_DOCTOR_BIND: 'PatientDoctorBind',
  USER_COURSE_CONSULTATION: 'UserCourseConsultation',
  USER_COURSE_PROCEDURE: 'UserCourseProcedure',
  USER_COURSE_MEDICINE: 'UserCourseMedicine',
  USER_COURSE_EXERCISE: 'UserCourseExercise',
  USER_COURSE_ANALYSIS: 'UserCourseAnalysis',
  USER_COURSE_EXTRATASK: 'UserCourseExtratask',
  USER_COURSE_RESEARCH: 'UserCourseResearch',
  USER_COURSE_MANIPULATION: 'UserCourseManipulation',
  RECORD_NEW: 'Новая запись',
  ERROR: 'error',
  TRASH: 'trash',
}

export const messageTypesColors = {
  ERROR: 'red',
  DEFAULT: 'black',
}

export const AUTO_CLOSE_TIMER = 30000
export const MESSAGES_LIMIT = 5
