/* eslint-disable comma-dangle */
import { useCallback, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { formatDateOrTodayDynamic } from 'helpers/Date'
import NotificationCardClinicRecord from 'components/notifications/cards/NotificationsCardClinicRecord'
import { useHistory } from 'react-router-dom'
import NotificationCardTask from './cards/NotificationCardTask'
import NotificationCardFile from './cards/NotificationCardFile'
import NotificationCardBindResult from './cards/NotificationCardBindResult'
import NotificationCardRemindConsultation from './cards/NotificationCardRemindConsultation'
import { PAGE_DASHBOARD_CLINIC_RECORD } from '../../constants/PathsTypes'

const NotificationCard = ({
  notification = {},
  isAdmin = false,
  onChatOpen = () => {},
  onDocumentOpen = () => {},
  onTaskOpen = () => {},
  onPatientOpen = () => {},
  onCloseModal = () => {},
  onRead = () => {},
}) => {
  const {
    push_type: pushType,
    data = { },
    created_at: createdAt = '',
    is_read: isRead = false,
  } = notification
  const history = useHistory()

  const {
    ref,
    inView,
  } = useInView({
    triggerOnce: true,
    rootMargin: '75%',
    threshold: 0.5
  })

  useEffect(() => {
    if (!isRead && inView && onRead) {
      onRead(notification.id)
    }
    // eslint-disable-next-line
  }, [isRead, inView])

  const handleRecordOpen = useCallback(() => {
    history.push(PAGE_DASHBOARD_CLINIC_RECORD)
  }, [ history ])

  const renderContent = useCallback(() => {
    const createdTitle = formatDateOrTodayDynamic(createdAt, ' в ')

    switch (pushType) {
    case 'TASKS_PUSH':
      if (data.remind_task) {
        return (
          <NotificationCardRemindConsultation
            created={createdTitle}
            data={data}
            onTask={onTaskOpen}
            isAdmin={isAdmin}
          />
        )
      }

      return (
        <NotificationCardTask
          created={createdTitle}
          data={data}
          onChat={onChatOpen}
          onTask={onTaskOpen}
          createdAt={createdAt}
          closeModal={onCloseModal}
        />
      )

    case 'UPLOADED_FILE':
      return (
        <NotificationCardFile
          created={createdTitle}
          onChat={onChatOpen}
          onDocument={onDocumentOpen}
          data={data}
          onClose={onCloseModal}
        />
      )

    case 'RECORDS_PUSH':
      return (
        <NotificationCardBindResult
          title={data.title}
          created={createdTitle}
          data={data}
          onChat={onChatOpen}
        />
      )
    case 'BINDS_PUSH':
      return (
        <NotificationCardBindResult
          created={createdTitle}
          data={data}
          isAdmin={isAdmin}
          onChat={onChatOpen}
          onPatient={onPatientOpen}
        />
      )
    case 'CLINIC_RECORD':
      return (
        <NotificationCardClinicRecord
          created={createdAt}
          data={data}
          handleRecordOpen={handleRecordOpen}
          isAdmin={isAdmin}
          onChat={onChatOpen}
          onPatient={onPatientOpen}
        />
      )
    default:
    }
  }, [
    data,
    isAdmin,
    pushType,
    createdAt,
    onChatOpen,
    onCloseModal,
    onPatientOpen,
    onDocumentOpen,
    onTaskOpen,
    handleRecordOpen
  ])

  return (

    <div ref={!isRead ? ref : null} className="notification-card">
      {renderContent()}
    </div>
  )
}

export default NotificationCard
