import React, { useCallback } from 'react'
import s from 'styled-components'
import ReactHtmlParser from 'react-html-parser'

const Styles = ({ theme }) => `
  max-width: 100%;
  display: block;
  word-wrap: break-word;
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }
  ul {
    li {
      position: relative;
      padding-left: 24px;
      list-style-type: none;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      &:after {
        content: "";
        position: absolute;
        left: 6px;
        top: 6px;
        width: 6px;
        height: 6px;
        border-radius: 100px;
        background: #1890D6;
      }
    }
  }
  ol {
    padding-left: 0px;
    li {
      margin-left: 24px;
    }
  }
  strong {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.18px;
  }
  & > div {
    max-width: 100%;
  }
  figure {
    max-width: 100%;
    display: flex;
    justify-content: center;
    width: fit-content;
    clear: both;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;

    &.image-style-align-left {
      clear: none;
      float: left;
      display: block;
      justify-content: end;
      margin-right: 10px;
    }
    &.image-style-align-right {
      clear: none;
      float: right;
      display: block;
      justify-content: start;
    }
    &.media {
      width: 100%;
      display: flex;
      justify-content :
    }
    &.image {
      img {
        border-radius: 10px;
        height: auto;
        width: 100%;
      }
    }
  }
  video, iframe {
    width: 700px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
  }

  &.--error .ck.ck-editor__editable_inline{
    border: 1px solid red !important;
  }
  table {
    border-collapse: collapse;
  }

  td, th {
    border: 1px solid #E5E5EA;
  }
  td {
    padding: 8px;
  }
  figure.table {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  figure.media {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`

export const ArticleContentStyled = s.div`
  ${(props) => Styles(props)}
`
const ArticleContent = ({
  article = '',
  isDirty = false,
  editorContent = '',
  ...props
}) => {
  const EditorToContent = useCallback(() => {
    let newArticle = article.text || ''
    newArticle = newArticle.replace(/<oembed(.*?)url=/g, '<oembed$1src=')
    const youtubeUrls = newArticle.match(/<oembed[^>]*src=['"](https:\/\/www\.youtube\.com\/watch\?v=([^'"]+))['"](.*?)><\/oembed>/g)

    // Заменить oembed на iframe с видео YouTube
    if (youtubeUrls) {
      youtubeUrls.forEach((url) => {
        const videoId = url.match(/https:\/\/www\.youtube\.com\/watch\?v=([^'"]+)/)[1]
        const iframeCode = `<iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`
        newArticle = newArticle.replace(url, iframeCode)
      })
    }
    newArticle = newArticle.replace(/<oembed/g, '<video controls')
    newArticle = newArticle.replace(/<\/oembed>/g, '</video>')
    return newArticle
  }, [ article ])

  return (
    <ArticleContentStyled>
      {ReactHtmlParser(EditorToContent())}
    </ArticleContentStyled>
  )
}

export default ArticleContent
