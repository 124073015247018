import React, { useEffect, useState } from 'react'
import ArticleContent from 'pages/Dashboard/Articles/ui/ArticleContent'
import ApiService from 'services/ApiService'
import { Spinner } from 'components/base'
import Box from 'layout/Box'
import CardImage from 'components/base/CardImage'
import Text from 'components/typo/Text'

const ArticleBase = ({ ...props }) => {
  const { id = null } = props
  const [ article, setArticle ] = useState(null)
  const [ isLoad, setIsLoad ] = useState(true)

  useEffect(() => {
    setArticle(null)
    setIsLoad(true)
    ApiService.apiCall({
      url: ApiService.paths.articles.TEMPLATE_BY_ID(id),
      isToken: true
    }).then((response) => {
      setArticle(response)
      setIsLoad(false)
    }).catch(() => {
      console.log('error')
    })
  }, [ id ])

  return (
    <>
      {article ? (
        <>
          <Box
            width="100%"
            jc="space-between"
            ai="start"
            className="mb-20"
          >
            <Text color="blue" size="mediumSm" fw="500">
              {article.title}
            </Text>
            {article.is_visible ? (
              <Box
                br="4px"
                bg="blue100"
                color="blue"
                className="pl-10 pr-10 ml-10"
                height="20px"
              >
                <Text
                  lh="20px"
                  size="normalSm"
                  nowrap
                >
                  Статья доступна всем врачам
                </Text>
              </Box>
            ) : ''}
          </Box>
          <Box
            className="mb-40"
          >
            {article.image ? (
              <Box
                width="122px"
                height="122px"
                br="8px"
                className="mr-30 mb-20"
                overflowHidden
                flex="0 0 auto"
              >
                <CardImage
                  width="100%"
                  height="100%"
                  variant="auto"
                  noLoading
                  objectFit="cover"
                  src={article.image}
                />
              </Box>
            ) : (
              <Box
                className="mr-30 mb-20"
                width="122px"
                height="122px"
                br="8px"
                bg="#E5F6FD"
                border="1px solid #a0aec0"
                jc="center"
                ai="center"
              >
                <Text
                  size="big"
                  color="blue"
                >
                  {article.title[0]}
                </Text>
              </Box>
            )}
            <Box
              direction="column"
            >
              <Text
                size="normalBig"
                fw="500"
                className="mb-1"
              >
                Краткое описание
              </Text>
              <Text
                size="normal"
                lh="26px"
              >
                {article.description}
              </Text>
            </Box>
          </Box>
        </>
      ) : ''}
      {!isLoad ? <ArticleContent article={article} /> : <Spinner />}
    </>

  )
}

export default ArticleBase
