import {
  useCallback, useRef, useState
} from 'react'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'

// components
import { Button } from 'components/base'
import { Form, FormGroup } from 'components/form'

// constants
import { ReactComponent as IconDocument } from 'assets/icons/list-check-small.svg'
import { ReactComponent as IconProfile } from 'assets/icons/user-2.svg'
import { SchemaAddStructureFile } from 'constants/ValidationSchemas'
import { uploadFile } from 'redux/Media/MediaActions'
import FilesDropzone from 'components/dropzones/FilesDropzone'
import { getMediaExt } from 'redux/Media/MediaHelpers'
import FormBlockMaskedField from 'components/form/FormBlockMaskedField'
import FormBlockField from 'components/form/FormBlockField'
import FormGroupBlock from 'components/form/FormGroupBlock'
import DropzoneCardFile from 'components/Dropzone/DropzoneCardFile'
import ModalAnswer from 'components/modals/ModalAnswer/ModalAnswer'
import {
  checkFullCorectFullTypes, getFileTypeNum, MediaFileTypes
} from '../../constants/MediaTypes'
import { convertBtoMB } from '../../helpers/Files'
import { formatDate } from '../../helpers/Date'

const initialModalData = {
  title: '',
  description: '',
  confirmText: 'Ок'
}

const AddStructureFileForm = ({
  closeModal = () => {},
  parentId = null,
  typeId = null,
  userId = null,
  isSearch = false,
  onAdd = () => {},
  data,
}) => {
  const [ isFile, setFile ] = useState(false)
  const [ isModal, setIsModal ] = useState(false)
  const [ modalData, setModalData ] = useState(initialModalData)
  const dispatch = useDispatch()
  const formRef = useRef()

  const onUpload = useCallback((files) => {
    if (files && files.length > 0) {
      if (files.length > 1) {
        setIsModal(true)
        setModalData({
          ...initialModalData,
          title: 'Можно загружать только один файл.',
          description: 'Выберете только один файл.'
        })
        return
      }
      if (!checkFullCorectFullTypes(files[0].type)) {
        setIsModal(true)
        setModalData({
          ...initialModalData,
          title: 'Формат файла не поддерживается',
          description: 'Формат загружаемого файла не поддерживается'
        })
        return
      }

      const maxSize = getFileTypeNum(files[0].type) === MediaFileTypes.VIDEO ? 300 : 50 // мбайт
      if (convertBtoMB(files[0].size) > maxSize) {
        setIsModal(true)
        setModalData({
          ...initialModalData,
          title: `Максимальный размер ${maxSize === 50 ? 'файла' : 'видео'}`,
          description: 'Максимальный размер загружаемого файла \n'
            + '- 100 мб'
        })
        return
      }

      setFile(true)

      if (formRef && formRef.current) {
        formRef.current.setFieldValue('file', files[0])

        setTimeout(() => {
          formRef.current.setFieldTouched('file', true)
        }, 300)
      }
    }
  }, [])

  const onClear = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.setFieldValue('file', null)
      formRef.current.setFieldTouched('file', false)
      setFile(false)
    }
  }, [])
  const handleSubmit = useCallback((values, { setSubmitting }) => {
    const { file } = values

    function onError () {
      setSubmitting(false)
    }

    const title = `${values.fullName}_${values.type}_${values.date}.${getMediaExt(file.name)}`

    dispatch(uploadFile({
      file,
      title
    }))
      .then((media) => {
        dispatch(onAdd({
          title: values.title,
          file: media[0].file.id,
          parentId,
          userId,
          isSearch,
          typeId
        }))
          .then(closeModal)
          .catch(onError)
      })
      .catch(onError)
  }, [
    isSearch,
    userId,
    typeId,
    parentId,
    onAdd,
    closeModal,
    dispatch,
  ])
  return (

    <>
      <Formik
        innerRef={formRef}
        initialValues={{
          fullName: data.user,
          type: '',
          date: formatDate(new Date(), 'dd.MM.yyyy'),
          file: null,
        }}
        onSubmit={handleSubmit}
        validationSchema={SchemaAddStructureFile}
      >
        {(props) => {
          const {
            values,
            dirty,
            isSubmitting,
            handleSubmit,
            isValid,
          } = props
          const formIsValid = dirty && isValid && isFile && !isSubmitting

          const fileTitle = [ values.fullName, values.type, values.date ]
            .filter((i) => !!i)
            .join('_')

          const isDropDisabled = !isValid || !dirty
          return (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                width: '100%'
              }}
              onSubmit={handleSubmit}
            >
              <div>

                <FormGroupBlock title="Выберите пациента">
                  <FormBlockField
                    name="fullName"
                    title="Пациент"
                    autoFocus
                    isAutoFocus
                    Icon={IconProfile}
                    placeholder="Введите ФИО пациента"
                    noMessage
                    value={values.fullName}
                  />
                </FormGroupBlock>

                <FormGroupBlock title="Документ">
                  <FormBlockField
                    name="type"
                    title="Тип документа"
                    placeholder="Введите тип документа"
                    Icon={IconDocument}
                    noMessage
                    value={values.type}
                  />
                  <FormBlockMaskedField
                    name="date"
                    title="Дата документа"
                    placeholder="Введите дату документа"
                    mask="99.99.9999"
                    maskChar="_"
                    value={values.date}
                  />
                </FormGroupBlock>
                <FormGroupBlock title="Файл">
                  {isFile ? <DropzoneCardFile onClear={onClear} title={fileTitle} file={values.file} /> : (
                    <FilesDropzone
                      width="100%"
                      onUpload={onUpload}
                      accept=""
                      isPreview={false}
                      isDisabled={isDropDisabled}
                    />
                  )}
                </FormGroupBlock>
              </div>
              <FormGroup className="mt-a">
                <Button
                  type="submit"
                  size="big"
                  full
                  title="Загрузить файл"
                  isDisabled={!formIsValid || isSubmitting}
                  isFetching={isSubmitting}
                  isGradient
                />
              </FormGroup>
            </Form>
          )
        }}
      </Formik>
      <ModalAnswer
        isOpen={isModal}
        onClose={() => {
          setIsModal(null)
          setModalData(initialModalData)
        }}
        onSuccess={() => {
          setIsModal(null)
          setModalData(initialModalData)
        }}
        title={modalData.title}
        description={modalData.description}
        confirmText={modalData.confirmText}
      />
    </>
  )
}

export default AddStructureFileForm
