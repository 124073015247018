import { useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import s from 'styled-components'

import Box from 'layout/Box'
import Draggable from 'components/Draggable'

import { updateCallDuration } from 'redux/Call/CallActions'

import CallStart from './CallStart'
import CallFullModal from './CallFullModal'
import { DRAGGABLE_CONTAINER_SIZES } from './call.config'
import './call.styles.scss'

const CALL_CONTAINER_ID = 'call-container'

const getStyles = () => `
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 22;
  flex-shrink: 0;
  transition: opacity 0.3s ease-in-out;
  transform: translateY(100%);
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.32);
  z-index: 10000;
  user-select: none;

  &.--draggable {
    bottom: 20px;
    left: 20px;
    width: ${DRAGGABLE_CONTAINER_SIZES.width}px;
    height: ${DRAGGABLE_CONTAINER_SIZES.height}px;
    background-color: rgba(0, 0, 0, 0);
  }

  &.--active {
    opacity: 1;
    transform: translateY(0%);
  }
`

const CallContainerContainer = s.div`
  ${getStyles}
`

const CallContainer = () => {
  const dispatch = useDispatch()
  const {
    currentCall = null,
    currentCallUser = null,
    isConnected = false,
    isFullscreen = false,
    containerPosition = {},
    isMuteAudio = false,
    isMuteVideo = false,
    isRemoteVideo = false,
    isLocalVideo = false,
    duration = 0,
  } = useSelector((state) => state.dashboard.call)
  const { profile } = useSelector((state) => state.user)

  const timerRef = useRef(null)

  useEffect(() => {
    if (isConnected) {
      let dur = 0
      timerRef.current = setInterval(() => {
        dur += 1
        dispatch(updateCallDuration(dur))
      }, 1000)
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current)
        dispatch(updateCallDuration(null))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isConnected ])

  const content = (props) => {
    const { pos, ...containerProps } = props || {}
    const isDraggable = isConnected && !isFullscreen
    const calcContProps = isDraggable ? containerProps : {}

    return (
      <CallContainerContainer
        id={CALL_CONTAINER_ID}
        className={cn('call-container', {
          '--active': currentCall,
          '--draggable': isDraggable
        })}
        {...calcContProps}
      >
        {currentCall && (
          <Box
            width="100%"
            height="100%"
            jc="center"
            ai="center"
          >
            {!isConnected && (
              <CallStart
                call={currentCall}
                callUser={currentCallUser}
              />
            )}

            <CallFullModal
              isFullscreen={isFullscreen}
              isConnected={isConnected}
              callUser={currentCallUser}
              newPosition={pos}
              currentPosition={containerPosition}
              isMuteAudio={isMuteAudio}
              isMuteVideo={isMuteVideo}
              isLocalVideo={isLocalVideo}
              isRemoteVideo={isRemoteVideo}
              duration={duration}
              me={profile}
            />
          </Box>
        )}
      </CallContainerContainer>
    )
  }

  return (
    <Draggable initPos={containerPosition}>
      {content}
    </Draggable>
  )
}

export default CallContainer
