import {
  useEffect, useRef, forwardRef
} from 'react'
import s from 'styled-components'
import cn from 'classnames'
import autosize from 'autosize'

export const Styles = ({
  theme,
  $size,
  $fs,
  $fw,
  $lh,
  $td,
  square,
  $bgColor,
  $minHeight,
  $maxHeight,
  $isPadded,
  $p,
  $placeholderColor,
  $minWidth,
  $maxWidth,
  $height,
  $width,
  $isOnlyShow,
}) => {
  const padding = $p || $isPadded
    ? $size === 'small'
      ? '8px 16px'
      : $size === 'xxs'
        ? '2px 8px'
        : '16px 16px 8px'
    : '0'

  return `
    font-family: ${theme.ff.main};
    font-size: ${theme.fs[$fs] || $fs || theme.fs.normal};
    font-weight: ${$fw ? theme.fw[$fw] || $fw : theme.fw.n};
    letter-spacing: ${theme.ls.main};
    line-height: ${$lh ? theme.lh[$lh] || $lh : theme.lh[$size] || theme.lh.main};
    display: flex;
    height: ${$height || $minHeight || ($size && theme.sizes[$size] ? theme.sizes[$size] : theme.sizes.normal)};
    width: ${$width || square ? theme.sizes[$size] : '100%'};
    min-height: ${$minHeight || ''};
    min-width: ${$minWidth || ''};
    max-width: ${$maxWidth || ''};
    max-height: ${$maxHeight || ''};
    align-items: stretch;
    padding: ${padding};
    transition: box-shadow 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    border-radius: ${theme.radius.normal};
    color: ${theme.colors.dark};
    text-decoration: ${$td};
    background-color: ${$bgColor ? theme.colors[$bgColor] : theme.colors.grey100};
    ${$bgColor ? `border: 1px solid ${theme.colors[$bgColor]}` : ''};

    @media (max-width: 360px) {
      font-size: ${theme.fs[$fs === 'big' ? 'normal' : 'small'] || $fs || theme.fs.normal};
      line-height: ${theme.lh.normal};
    }

    &.--s {
      &-bg {
      }

      &-transparent {
        background-color: ${theme.colors.transparent} !important;
        padding-left: 0;
        padding-right: 0;
      }

      &-bordered
        color: ${theme.colors.red};
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
        border-bottom: 1px solid ${theme.colors.grey150};
        background-color: ${theme.colors.white} !important;
        transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out;

        &:focus {
          border-color: ${theme.colors.black};
        }

        &.--error:not(:focus) {
          border-color: ${theme.colors.red};
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          box-shadow: 0 0 0 30px ${theme.colors.white} inset !important;
        }
      }
    }

    &.--a {
      &-center {
        text-align: center;
      }
    }

    &:hover:not(.--s-bordered):not(:focus) {
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: ${theme.colors.grey600};
        opacity: 1;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${theme.colors.grey600};
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${theme.colors.grey600};
      }

      &::placeholder {
        /* Most modern browsers support this now. */
        color: ${theme.colors.grey600};
      }
    }

    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: ${theme.colors[$placeholderColor] || $placeholderColor || theme.colors.grey500};
      opacity: 1;
      transition color 0.3s ease-in-out;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      transition color 0.3s ease-in-out;
      color: ${theme.colors[$placeholderColor] || $placeholderColor || theme.colors.grey500};
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      transition color 0.3s ease-in-out;
      color: ${theme.colors[$placeholderColor] || $placeholderColor || theme.colors.grey500};
    }

    &::placeholder {
      /* Most modern browsers support this now. */
      transition color 0.3s ease-in-out;
      color: ${theme.colors[$placeholderColor] || $placeholderColor || theme.colors.grey500};
    }

    &.--error {
      color: ${theme.colors.red};

      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: ${theme.colors.red};
        opacity: 1;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${theme.colors.red};
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${theme.colors.red};
      }

      &::placeholder {
        /* Most modern browsers support this now. */
        color: ${theme.colors.red};
      }
    }

    &.--error:focus:not(.--readonly),
    &:focus:not(.--readonly) {
      background-color: ${$bgColor ? theme.colors[$bgColor] || $bgColor : theme.colors.grey100};
      color: ${theme.colors.dark};
    }

    &.--error:not(:focus)  {
      color: ${theme.colors.grey500};
      border-color: ${theme.colors.red};


      &:hover {
        color: ${theme.colors.grey800};
      }
    }

    &.:focus {
      color: ${theme.colors.dark} !important;

      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: ${theme.colors.grey500};
        opacity: 1;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${theme.colors.grey500};
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${theme.colors.grey500};
      }

      &::placeholder {
        /* Most modern browsers support this now. */
        color: ${theme.colors.grey500};
      }
    }

    &:disabled {
      ${$isOnlyShow || `color: ${theme.colors.grey300};`}
    }

    &.--readonly {
      color: ${theme.colors.dark};
    }

    &.--error {
      &::placeholder {
        ${$bgColor ? `color: ${theme.colors.grey500};` : ''};
      }

      &::placeholder:hover {
        ${$bgColor ? `color: ${theme.colors.grey800};` : ''};
      }
    }
    &.--ta-autoresize {
      resize: auto !important;
    }
  `
}

const InputContainer = s.input`
  ${(props) => Styles(props)}
`

const InputContainerTextarea = s.textarea`
  ${(props) => Styles(props)}
`

const Input = forwardRef(({
  tag = false,
  className = '',
  size = 'normal',
  autoComplete = '',
  isError = false,
  readOnly = false,
  align = '',
  name = null,
  isPadded = true,
  bgColor = '',
  isAutoResize = false,
  isAutoFocus = false,
  minHeight = '',
  maxHeight = '',
  minWidth = '',
  maxWidth = '',
  noBorders = false,
  defaultStyle = 'bg',
  value = '',
  placeholderColor = 'grey500',
  rows = '',
  fs = '',
  fw = '',
  lh = '',
  td = '',
  p = '',
  height = '',
  width = '',
  isDisabled = false,
  isOnlyShow = false,
  ...otherProps
}, outsideRef) => {
  const newRef = useRef(null)
  const ref = outsideRef || newRef
  const classes = cn('w-input', {
    '--error': isError,
    '--readonly': readOnly,
    [`--a-${align}`]: align,
    '--ta-autoresize': isAutoResize,
    '--no-borders': noBorders,
    [`--s-${defaultStyle}`]: defaultStyle,
  }, className)

  useEffect(() => {
    if (isAutoResize && tag === 'textarea' && ref && ref.current) {
      setTimeout(() => {
        autosize(ref.current)
      }, 100)
    }
    // eslint-disable-next-line
  }, [ isAutoResize, ref ])

  useEffect(() => {
    if (isAutoFocus && ref && ref.current && ref.current.focus) {
      const len = value ? value.length : 0
      ref.current.focus()
      ref.current.setSelectionRange(len, len)
    }
    // eslint-disable-next-line
  }, [ isAutoFocus, ref ])

  useEffect(() => {
    if (ref.current && ref.current.blur && readOnly) {
      ref.current.blur()
    }
    // eslint-disable-next-line
  }, [ readOnly ])

  const props = {
    ...otherProps,
    disabled: isDisabled || isOnlyShow,
    value,
    ref,
    $size: size,
    $fs: fs,
    $fw: fw,
    $lh: lh,
    $td: td,
    $height: height,
    $width: width,
    $p: p,
    name,
    $bgColor: bgColor,
    className: classes,
    autoComplete,
    readOnly,
    $isPadded: isPadded,
    $minHeight: minHeight,
    $maxHeight: maxHeight,
    $minWidth: minWidth,
    $maxWidth: maxWidth,
    $placeholderColor: placeholderColor,
    $isOnlyShow: isOnlyShow,
  }

  if (tag === 'textarea') {
    props.rows = rows
  }

  const Input = tag === 'textarea'
    ? InputContainerTextarea
    : InputContainer

  return <Input {...props} />
})

Input.displayName = 'Input'

export default Input
