import Text from 'components/typo/Text'
import styled from 'styled-components'
import Box from 'layout/Box'
import VideoLazy from 'components/base/VideoLazy'
import ReactHtmlParser from 'react-html-parser'
import ApiService from 'services/ApiService'
import { useEffect, useState } from 'react'
import { logError } from 'utils/utils'
import { Spinner } from 'components/base'
import { checkCorrectUrl } from 'helpers/System'

const KnowledgeBaseProcedureContainer = styled.div`
  .titleImg {
    height: 80px;
    width: 80px;
    border-radius: 10px;
    object-fit: cover;
  }

  .htmlParse {
    p {
      margin-top: 8px;

      &:first-child {
        margin-top: 0;
      }
    }

    h1, h2, h3, h4, h5, h6 {
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }
    }

    li {
      margin-left: 16px;
    }
  }
`

const KnowledgeBaseProcedure = ({ id }) => {
  const [ data, setData ] = useState(null)
  const [ isError, setIsError ] = useState(false)

  useEffect(() => {
    ApiService.apiCall({
      url: ApiService.paths.knowledgebase.PROCEDURE_BY_ID(id),
      isToken: true
    }).then((response) => {
      setData(response)
    }).catch((error) => {
      setIsError(true)
      logError('Не удалось получить данные из базы знаний', error)
    })
  }, [ id ])

  return (
    <KnowledgeBaseProcedureContainer>
      {data ? (
        <>
          <Text fw="m" fs="20px" className="mb-20" tag="p" size="l" color="blue">
            {data.name}
          </Text>
          <Box className="mb-6" flex="1">
            {data.image && (
              <img
                src={checkCorrectUrl(data.image)}
                className="titleImg mr-6"
                alt=""
              />
            )}
            {data.description && (
              <div>
                <Text fw="m" fs="18px" className="mb-2" tag="p" size="l">
                  Краткое описание:
                </Text>
                <div className="htmlParse">
                  {ReactHtmlParser(data.description)}
                </div>
              </div>
            )}
          </Box>
          {data.requirements && (
            <div className="mb-6">
              <Text fw="m" fs="18px" className="mb-2" tag="p" size="l">
                Показания:
              </Text>
              <div className="htmlParse">{ReactHtmlParser(data.requirements)}</div>
            </div>
          )}
          {data.contraindications && (
            <div className="mb-6">
              <Text fw="m" fs="18px" className="mb-2" tag="p" size="l">
                Противопоказания:
              </Text>
              <div className="htmlParse">{ReactHtmlParser(data.contraindications)}</div>
            </div>
          )}
          {data.task_description && (
            <div className="mb-6">
              <Text fw="m" fs="18px" className="mb-2" tag="p" size="l">
                Код:
              </Text>
              <div className="htmlParse">{ReactHtmlParser(data.task_description)}</div>
            </div>
          )}
          {data.video && (
            <div className="mb-6">
              <Text fw="m" fs="18px" className="mb-2" tag="p" size="l">
                Видео:
              </Text>
              <VideoLazy src={data.video} width="100%" />
            </div>
          )}
        </>
      ) : isError ? <div>Ошибка, страница не доступна</div> : <Spinner />}
    </KnowledgeBaseProcedureContainer>
  )
}

export default KnowledgeBaseProcedure
