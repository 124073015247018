import { useMemo } from 'react'
import s from 'styled-components'
import cn from 'classnames'

const Styles = ({
  theme,
  $display,
  $direction,
  $width,
  $maxWidth,
  $minWidth,
  $height,
  $minHeight,
  $maxHeight,
  $m,
  $p,
  $ai,
  $self,
  $jc,
  $flex,
  $br,
  $border,
  $gap,
  $textAlign,
  $pointer,
  $isWrap,
  $bg,
  $bs,
  $order,
  $zIndex,
  $childrenAlign,
  $childrenJustify,
  $right,
  $left,
  $bottom,
  $top,
  $position,
  $color,
}) => {
  const cursor = $pointer === true ? 'pointer' : $pointer

  return `
  display: ${$display || 'flex'};
  height: ${$height || ''};
  gap: ${$gap || ''};
  min-height: ${$minHeight || ''};
  max-height: ${$maxHeight || ''};
  width: ${$maxWidth ? '100%' : $width || 'auto'};
  max-width: ${$maxWidth || ''};
  min-width: ${$minWidth || ''};
  background-color: ${theme.colors[$bg] || $bg};
  flex-direction: ${$direction || ''};
  align-items: ${$ai || ''};
  align-self: ${$self || ''};
  justify-content: ${$jc || ''};
  margin: ${$m || ''};
  padding: ${theme.paddings[$p] || $p};
  flex: ${$flex || ''};
  border: ${$border || ''};
  border-radius: ${theme.radius[$br] || $br};
  flex-wrap: ${$isWrap ? 'wrap' : ''};
  cursor: ${cursor};
  text-align: ${$textAlign || ''};
  z-index: ${$zIndex || ''};
  box-shadow: ${$bs};
  order: ${$order || ''};
  right: ${$right || ''};
  left: ${$left || ''};
  bottom: ${$bottom || ''};
  top: ${$top || ''};
  position: ${$position || 'static'};
  color: ${theme.colors[$color] || ''};

  &.--no-shrink {
    flex-shrink: 0;
  }

  & > * {
    align-self: ${$childrenAlign};
    justify-self: ${$childrenJustify};
  }

  &.--rel {
    position: relative;
  }

  &.--o-hidden {
    overflow: hidden
  }

  &.--o-y {
    &-scroll {
      overflow-y: scroll;
    }
    &-auto {
      overflow-y: auto;
    }
  }
`
}

const BoxContainerDiv = s.div`
  ${(props) => Styles(props)}
`

const BoxContainerSpan = s.span`
  ${(props) => Styles(props)}
`

const BoxContainerLabel = s.label`
  ${(props) => Styles(props)}
`

const Box = ({
  children = null,
  as = '',
  className = '',
  border = '',
  br = '',
  display = 'flex',
  direction = '',
  width = '',
  maxWidth = '',
  minWidth = '',
  height = '',
  minHeight = '',
  maxHeight = '',
  order = '',
  m = '',
  p = '',
  ai = '',
  self = '',
  jc = '',
  flex = '',
  textAlign = '',
  pointer = '',
  bg = '',
  bs = '',
  gap = '',
  zIndex = '',
  isWrap = false,
  isRelative = false,
  overflowHidden = false,
  overflowY = null,
  onClick = null,
  childrenAlign = '',
  childrenJustify = '',
  noShrink = false,
  right = '',
  left = '',
  bottom = '',
  top = '',
  position = '',
  innerRef = null,
  color = '',
  onScroll = null,
  ...otherProps
}) => {
  const classes = cn('w-box', {
    '--rel': isRelative,
    '--o-hidden': overflowHidden,
    '--no-shrink': noShrink,
    [`--o-y-${overflowY}`]: overflowY,
  }, className)

  const props = {
    className: classes,
    $display: display,
    $direction: direction,
    $width: width,
    $maxWidth: maxWidth,
    $minWidth: minWidth,
    $height: height,
    $minHeight: minHeight,
    $maxHeight: maxHeight,
    $m: m,
    $p: p,
    $ai: ai,
    $self: self,
    $jc: jc,
    $flex: flex,
    $br: br,
    $border: border,
    $gap: gap,
    $textAlign: textAlign,
    $childrenAlign: childrenAlign,
    $childrenJustify: childrenJustify,
    $pointer: pointer,
    $isWrap: isWrap,
    $bg: bg,
    $bs: bs,
    $zIndex: zIndex,
    $order: order,
    $right: right,
    $left: left,
    $bottom: bottom,
    $top: top,
    $position: position,
    $color: color,
    onClick,
    innerRef,
    ...otherProps,
  }

  const BoxContainer = useMemo(() => {
    switch (as) {
    case 'span':
      return BoxContainerSpan
    case 'label':
      return BoxContainerLabel
    case 'div':
    default:
      return BoxContainerDiv
    }
  }, [ as ])

  return <BoxContainer ref={innerRef} {...props}>{children}</BoxContainer>
}

export default Box
