/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
/* eslint-disable guard-for-in */
// @ts-ignore
import {
  log,
  logError,
  logHelp
} from 'utils/utils'
import * as VoxImplant from 'voximplant-websdk'
import store from 'redux/store/configureStore'
import { onConversationCreated, updateCurrentConversationLastEvent } from 'redux/dashboard/Messanger/MesengerConversationsActions'
import {
  onMessageMarkAsRead,
  onMessageSent,
  onOnlineReceived,
} from 'redux/dashboard/Messanger/MessengerMessagesActions'
import { getUserUri } from 'redux/dashboard/Chat/ChatHelpers'
import {
  MY_APP,
  URL_NEW_USERS
} from './messenger.config'

const TIME_NOTIFICATION = 5000

export default class MessengerService {
  constructor() {
    this.messenger = null
    this.inst = null
    this.setStatusTimer = null
    this.conversationEvents = {}
  }

  static get = () => {
    if (!MessengerService.inst) {
      MessengerService.inst = new MessengerService()
    }
    return MessengerService.inst
  }

  removeChat = () => Promise.all([
    this.sendStatusOfflineStatus(),
    this.removeMessengerEventListeners()
  ])

  /** *********************************************************************************************
   * INIT VOXIMPLANT.MESSENGER && GET INITIAL DATA
   ********************************************************************************************* */

  init = async () => {
    // Get Voximplant Messenger instance
    try {
      MessengerService.messenger = VoxImplant.getMessenger()
      log('Messenger v2', MessengerService.messenger)
      log('VoxImplant.Messaging v2', VoxImplant.Messaging)
    }
    catch (e) {
      // Most common error 'Not authorised', so redirect to login
      logError(e)
      await store.dispatch('auth/relogin')
    }

    // Get the current user data
    const initialData = {
      currentUser: {},
      conversations: [],
      users: [],
    }

    await MessengerService.messenger.getUser(MessengerService.messenger.getMe())
      .then(async (evt) => {
        logHelp('Current user data received', evt)
        initialData.currentUser = evt.user
        const allConv = evt.user.conversationsList || []
        const allConvLength = allConv.length
        const splittedConv = []
        const cutLength = 30

        for (let idx = 0; idx < allConvLength; idx += cutLength) {
          const idxLast = idx + cutLength
          splittedConv.push(allConv.slice(idx, idxLast))

          if (allConvLength - idxLast < cutLength && allConvLength - idxLast > 1) {
            splittedConv.push(allConv.slice(idxLast))
            break
          }
        }

        return Promise.all(splittedConv.map((convRow) => this.getCurrentConversations(convRow)))
      })
      .then((convArrs) => {
        const evts = convArrs.flat()
        logHelp('Current user conversations received', evts)

        initialData.conversations = evts.length ? evts.map((e) => e.conversation) : []
        const directUsersId = initialData.conversations.filter((conversation) => conversation.direct).map((conversation) => {
          const directUser = conversation.participants.find((participant) => participant.userId !== initialData.currentUser.userId)
          return directUser.userId
        })

        // subscribe to get status events
        if (directUsersId.length) {
          const usersLength = directUsersId.length
          const splittedUsers = []

          for (let idx = 0; idx < usersLength; idx += 30) {
            const idxLast = idx + 30
            splittedUsers.push(directUsersId.slice(idx, idxLast))

            if (usersLength - idxLast < 30 && usersLength - idxLast > 1) {
              splittedUsers.push(directUsersId.slice(idxLast))
              break
            }
          }

          splittedUsers.forEach((usersRow) => {
            MessengerService.messenger.subscribe(usersRow)
              .then((e) => {
                log('Subscribed to conversation participants', e)
              })
              .catch(logError)
          })

          return Promise.all(splittedUsers.map((usersRow) => MessengerService.messenger.getUsersById(usersRow)))
        }

        return Promise.reject(new Error('No users'))
      })
      .then((usersEvts) => {
        const evts = usersEvts.flat()
        logHelp('Conversation participants user info received', evts)
        initialData.users = evts.map((e) => e.user)
      })
      .catch(logError)

    this.addMessengerEventListeners()

    /**
     * You have to send user presence status periodically to notify the new coming users if you are online
     * TODO You can implement invisible mode by sending setStatus(false)
     */
    const sendStatus = () => setTimeout(() => {
      MessengerService.messenger.setStatus(true)
      this.setStatusTimer = sendStatus()
    }, TIME_NOTIFICATION)

    this.setStatusTimer = sendStatus()
    return initialData
  }

  sendStatusOfflineStatus = () => {
    if (MessengerService.messenger) {
      clearTimeout(this.setStatusTimer)
      return MessengerService.messenger.setStatus(false)
    }
  }

  listenerStatus = (e) => store.dispatch(onOnlineReceived(e))

  listenerCreateConversation = (e) => store.dispatch(onConversationCreated(e))

  listenerEditConversation = (e) => store.dispatch({
    type: 'conversations/onConversationEdited',
    payload: e
  })

  listenerSendMessage = (e) => store.dispatch(onMessageSent(e))

  listenerEditMessage = (e) => store.dispatch({
    type: 'conversations/onMessageEdited',
    payload: e
  })

  listenerRemoveMessage = (e) => store.dispatch({
    type: 'conversations/onMessageDeleted',
    payload: e
  })

  listenerMarkAsRead = (e) => store.dispatch(onMessageMarkAsRead(e))

  listenerTyping = (e) => store.dispatch({
    type: 'conversations/onNotifyTyping',
    payload: e
  })

  /**
   * Some VoxImplant.Messaging.MessengerEvents are better to use by passing a callback to the event listener function.
   * This way you're able update the current user's store and interface if the event is triggered by another user or by the current one on a different device.
   * The other VoxImplant.Messaging.MessengerEvents are more handy to deal with in a .then() function as they all return a Promise.
   * These are methods that affect only this user and this application instance, like subscribing to or unsubscribing from other users, retransmitting events or getting other data.
   */
  addMessengerEventListeners = () => {
    // Listen to other users presence status event
    MessengerService.messenger.on(VoxImplant.Messaging.MessengerEvents.SetStatus, this.listenerStatus)

    // Listen to CreateConversation event called by this or another user
    MessengerService.messenger.on(VoxImplant.Messaging.MessengerEvents.CreateConversation, this.listenerCreateConversation)

    // Listen to EditConversation event called by this or another user
    MessengerService.messenger.on(VoxImplant.Messaging.MessengerEvents.EditConversation, this.listenerEditConversation)

    // Listen to incoming messages
    MessengerService.messenger.on(VoxImplant.Messaging.MessengerEvents.SendMessage, this.listenerSendMessage)

    // Listen to edited messages
    MessengerService.messenger.on(VoxImplant.Messaging.MessengerEvents.EditMessage, this.listenerEditMessage)

    // Listen to deleted messages
    MessengerService.messenger.on(VoxImplant.Messaging.MessengerEvents.RemoveMessage, this.listenerRemoveMessage)

    // Listen to markAsRead message
    MessengerService.messenger.on(VoxImplant.Messaging.MessengerEvents.Read, this.listenerMarkAsRead)

    // Listen to typing event
    MessengerService.messenger.on(VoxImplant.Messaging.MessengerEvents.Typing, this.listenerTyping)
  }

  removeMessengerEventListeners = () => {
    if (MessengerService.messenger && MessengerService.messenger.off) {
      return Promise.all(
        [
          MessengerService.messenger.off(VoxImplant.Messaging.MessengerEvents.SetStatus, this.listenerStatus),
          MessengerService.messenger.off(VoxImplant.Messaging.MessengerEvents.CreateConversation, this.listenerCreateConversation),
          MessengerService.messenger.off(VoxImplant.Messaging.MessengerEvents.EditConversation, this.listenerEditConversation),
          MessengerService.messenger.off(VoxImplant.Messaging.MessengerEvents.SendMessage, this.listenerSendMessage),
          MessengerService.messenger.off(VoxImplant.Messaging.MessengerEvents.EditMessage, this.listenerEditMessage),
          MessengerService.messenger.off(VoxImplant.Messaging.MessengerEvents.RemoveMessage, this.listenerRemoveMessage),
          MessengerService.messenger.off(VoxImplant.Messaging.MessengerEvents.Read, this.listenerMarkAsRead),
          MessengerService.messenger.off(VoxImplant.Messaging.MessengerEvents.Typing, this.listenerTyping),
          MessengerService.messenger.unsubscribe([], true)
        ]
      )
    }
  }

  /**
   * The maximum number of conversations that SDK enables to get at once is 30
   * This method resolves to an array of VoxImplant.Messaging.MessengerEvents.GetConversation events
   */
  // TODO add param for amount of conversations
  // @ts-ignore
  getCurrentConversations = (conversationsList = []) => MessengerService.messenger
    .getConversations(conversationsList)
    .catch((e) => {
      logError('MessengerService.getCurrentConversations', e)
      return []
    })

  /**
   * Get all available users names through additional PHP server
   */

  getUserByName = (username) => {
    const userUri = getUserUri(username)
    return MessengerService.messenger.getUser(userUri)
  }

  getUserById = (directUserId) => MessengerService.messenger.getUserById(directUserId)

  /**
   * Get all available users names through additional PHP server
   */
  getAllUsers = async () => {
    const getAllUsers = await fetch(URL_NEW_USERS)
    let jsonAllUsers

    if (getAllUsers.ok) {
      jsonAllUsers = await getAllUsers.json()
    }
    else {
      logError(`Error HTTP: ${getAllUsers.status}`)
    }
    const usersNames = jsonAllUsers.result.map((u) => `${u.user_name}@${MY_APP}`)
    return this.getUserIds(usersNames)
  }

  /**
   * All Messenger methods except for GetUser and GetUsers accept only Messaging user ids, so you often need to map user names to user ids.
   * Messenger.GetUser and Messenger.GetUsers accept user names.
   */
  getUserIds = (filteredUserNames = []) => MessengerService.messenger.getUsers(filteredUserNames)

  /** *********************************************************************************************
   * CREATE CONVERSATIONS
   ********************************************************************************************* */

  createDirect = (userId) => this.createNewConversation(
    [{ userId }],
    '',
    true,
    false,
    false,
    { type: 'direct' }
  )

  createChat = (newChatData) => {
    const permissions = {
      canWrite: true,
      canEdit: true,
      canRemove: true,
      canManageParticipants: true,
      canEditAll: false,
      canRemoveAll: false,
    }

    const participants = newChatData.usersId.map((userId) => ({
      userId,
      ...permissions,
    }))

    return this.createNewConversation(
      participants,
      newChatData.title,
      false,
      newChatData.isPublic,
      newChatData.isUber,
      {
        type: 'chat',
        image: newChatData.avatar,
        description: newChatData.description,
        permissions,
      }
    )
  }

  createChannel = (newChatData) => {
    const permissions = {
      canWrite: false,
      canEdit: false,
      canRemove: false,
      canManageParticipants: true,
      canEditAll: false,
      canRemoveAll: false,
    }

    const participants = newChatData.usersId.map((userId) => ({
      userId,
      ...permissions,
    }))

    return this.createNewConversation(
      participants,
      newChatData.title,
      false,
      newChatData.isPublic,
      newChatData.isUber,
      {
        type: 'channel',
        image: newChatData.avatar,
        description: newChatData.description,
        permissions,
      }
    )
  }

  /**
   * Messenger.createConversation method takes 6 arguments
   * @param participants {Array<{userId: number, canWrite: boolean, canEdit: boolean, canRemove: boolean, canManageParticipants: boolean, canEditAll: boolean, canRemoveAll: boolean}>} - Array of participants
   * @param title {string} - Conversation name
   * @param direct {boolean} - True you create a conversation between two users
   * @param publicJoin {boolean} - True if anyone can join the conversation
   * @param uber {boolean} - True if anyone can join the conversation
   * @param customData {Array<object>} - Array of any objects with custom data
   */
  createNewConversation = (
    participants = [],
    title = '',
    direct = false,
    publicJoin = false,
    uber = false,
    customData = {}
  ) => MessengerService.messenger.createConversation(
    participants,
    title,
    direct,
    publicJoin,
    uber,
    customData
  )

  /** *********************************************************************************************
   * EDIT/LEAVE CONVERSATIONS
   ********************************************************************************************* */

  /**
   * These methods triggers VoxImplant.Messaging.MessengerEvents.EditConversation event
   * VoxImplant.Messaging method takes an array of {userId: number, canWrite: boolean, canEdit: boolean, canRemove: boolean, canManageParticipants: boolean, canEditAll: boolean, canRemoveAll: boolean}
   */
  addParticipants = (currentConversation, userIds = []) => currentConversation.addParticipants(userIds.map((userId) => ({
    userId,
    ...currentConversation.customData.permissions,
  }))).catch(logError)

  removeParticipants = (currentConversation, userIds = []) => currentConversation
    .removeParticipants(userIds.map((userId) => ({ userId })))
    .catch(logError)

  addAdmins = (currentConversation, userIds = []) => currentConversation
    .editParticipants(userIds.map((userId) => ({
      userId,
      canWrite: true,
      canEdit: true,
      canRemove: true,
      canManageParticipants: true,
      canEditAll: true,
      canRemoveAll: true,
      isOwner: true,
    }))).catch(logError)

  // default chat permissions from custom data
  removeAdmins = (currentConversation, userIds = []) => currentConversation
    .editParticipants(userIds.map((userId) => ({
      userId,
      ...currentConversation.customData.permissions,
    })))
    .catch(logError)

  editPermissions = (currentConversation, permissions = [], allUserIds = []) => {
    // NOTE! Must merge previous custom data with new
    // Method setCustomData REPLACE data
    currentConversation.setCustomData({
      ...currentConversation.customData,
      permissions
    })

    return currentConversation.editParticipants(allUserIds.map((userId) => ({
      userId,
      ...permissions,
    }))).catch(logError)
  }

  leaveConversation = (currentConversationUuid = '') => {
    MessengerService.messenger.leaveConversation(currentConversationUuid)
      .catch(logError)
  }

  /**
   * Notify other that current user typing in conversation. This method trigger VoxImplant.Messaging.MessengerEvents.Typing event
   * Subscribe it to resolve events, min time between notifications 10sec
   * @param currentConversation
   */
  notifyTyping = (currentConversation) => currentConversation.typing()

  /** *********************************************************************************************
   * MESSAGES
   ********************************************************************************************* */

  /**
   * The maximum number of events you can retransmit at once is 100.
   * retransmitEvents method resolves to the event containing an array of VoxImplant.Messaging.MessengerEvents
   * params => eventsFrom: number, eventsTo: number, count?: number
   */
  retransmitMessageEvents = (currentConversation, lastEvent, isFirst) => {
    lastEvent = Number(lastEvent || currentConversation.lastSeq)
    const eventFrom = lastEvent - 20 > 0 ? lastEvent - 20 : 1
    store.dispatch(updateCurrentConversationLastEvent(eventFrom - 1))

    if (isFirst) {
      this.conversationEvents[currentConversation.uuid] = null
    }

    return currentConversation.retransmitEvents(Number(eventFrom), Number(lastEvent))
      .then((e) => {
        const allEvents = this.conversationEvents[currentConversation.uuid]
        this.conversationEvents[currentConversation.uuid] = [ ...e.events, ...(allEvents || []) ]

        const sendAction = VoxImplant.Messaging.MessengerAction.sendMessage
        const editAction = VoxImplant.Messaging.MessengerAction.editMessage
        const deleteAction = VoxImplant.Messaging.MessengerAction.removeMessage
        const messageEvents = []
        const filteredEvents = this.conversationEvents[currentConversation.uuid]
          .filter((e) => (
            e.messengerAction === sendAction
            || e.messengerAction === editAction
            || e.messengerAction === deleteAction
          ))

        if (!filteredEvents.length) return []

        // Group by message.uuid
        const groupByUuidEvents = filteredEvents.reduce((res, evt) => {
          res[evt.message.uuid] = res[evt.message.uuid] || []
          res[evt.message.uuid].push(evt)
          return res
        }, Object.create(null))

        // Get only relevant events for message.uuid
        for (const messageUuid in groupByUuidEvents) {
          const arrEvtsMessage = groupByUuidEvents[messageUuid]
          const isDeleted = arrEvtsMessage.find((m) => m.messengerAction === deleteAction)
          const isEdited = arrEvtsMessage.find((m) => m.messengerAction === editAction)
          if (isDeleted) {
            continue
          }
          else if (isEdited) {
            if (!arrEvtsMessage.find((m) => m.messengerAction === sendAction)) continue

            const sorted = arrEvtsMessage.sort((m) => m.timestamp)
            const initialMessage = sorted[0]
            const lastUpdated = sorted[sorted.length - 1]
            lastUpdated.message.editedAt = lastUpdated.timestamp
            lastUpdated.timestamp = initialMessage.timestamp
            lastUpdated.message.editedBy = lastUpdated.initiator
            lastUpdated.initiator = initialMessage.initiator
            logHelp('retransmit edited', initialMessage, lastUpdated)
            messageEvents.push(lastUpdated)
          }
          else {
            messageEvents.push(...arrEvtsMessage)
          }
        }

        logHelp(`All events in conversation ${currentConversation.title}`, this.conversationEvents[currentConversation.uuid])

        return messageEvents
      })
      // @ts-ignore
      .catch((e) => {
        logError('Retransmit message events fail', e)
        return []
      })
  }

  sendMessage = (currentConversation, text = '', payload = [{}]) => currentConversation
    .sendMessage(text, payload)
    .catch(logError)

  removeMessage = (message) => message
    .remove()
    .catch(logError)

  updateMessage = (message) => message
    .update()
    .catch(logError)

  markAsRead = (conversation, lastSeq) => conversation
    .markAsRead(lastSeq)
    .catch(logError)

  /** *********************************************************************************************
   * EDIT USERS INFO
   ********************************************************************************************* */

  editUserCustomData = (customData = {}) => MessengerService.messenger.editUser(customData)
}
