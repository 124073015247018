import s from 'styled-components'
import cn from 'classnames'
import { checkCorrectUrl } from 'helpers/System'

const getStyles = ({
  theme,
  $width,
  $height,
  $bgColor = '',
}) => `
  display: block;
  width: ${$width};
  height: ${$height};
  background-color: ${theme.colors[$bgColor]};
  border-radius: 10px;
  object-fit: cover;

  &.--a-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const VideoContainer = s.video`
  ${getStyles}
`

const Video = ({
  className = '',
  poster = '',
  src = '',
  width = '',
  height = '',
  controls = true,
  onClick = () => {},
  isCentered = false,
  bgColor = '',
}) => (
  <VideoContainer
    poster={poster}
    src={checkCorrectUrl(src)}
    $width={width}
    $height={height}
    controls={controls}
    $bgColor={bgColor}
    onClick={onClick}
    className={cn(
      'c-video',
      { '--a-center': isCentered },
      className,
    )}
  >
    <source src={src} type="video/*" />
    <track src={src} kind="captions" />
  </VideoContainer>
)

export default Video
