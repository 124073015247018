export const TasksTypes = {
  ALL: 'all',
  EXERCISES: 'exercise',
  MEDICINE: 'medicine',
  ANALYSES: 'analysis',
  PROCEDURE: 'procedure',
  CONSULTATION: 'consultation',
  MANIPULATION: 'manipulation',
  RESEARCHES: 'research',
  EXTRATASKS: 'extratask',
  ARTICLE: 'article',
  EVENT: 'event',
}

export const TaskNotificationTypes = {
  UserCourseExercise: 'UserCourseExercise',
  UserCourseMedicine: 'UserCourseMedicine',
  UserCourseConsultation: 'UserCourseConsultation',
  UserCourseAnalysis: 'UserCourseAnalysis',
  UserCourseManipulation: 'UserCourseManipulation',
  UserCourseProcedure: 'UserCourseProcedure',
  UserCourseResearch: 'UserCourseResearch',
  UserCourseExtratask: 'UserCourseExtratask',
  UserCourseEvent: 'UserCourseEvent',
}

export const TaskStatusesTypes = {
  WAITING: 'waiting',
  COMPLETED: 'completed',
  EXPIRED: 'expired',
}

export const TaskSourceTypes = {
  STANDALONE: 'standalone',
  COURSE: 'course',
  SERIES: 'series'
}

export const TaskSourceTypesName = {
  [TaskSourceTypes.STANDALONE]: 'Задание',
  [TaskSourceTypes.COURSE]: 'Курс',
  [TaskSourceTypes.SERIES]: 'Серия',
}

export const TaskSourceColors = {
  [TaskStatusesTypes.WAITING]: 'blue',
  [TaskStatusesTypes.COMPLETED]: 'green500',
  [TaskStatusesTypes.EXPIRED]: '#FFA800',
  expiredHigh: 'red',
}

export const TasksDaysTypes = {
  EVERYDAY: 'EVERYDAY',
  MON: 0,
  TUE: 1,
  WEN: 2,
  THU: 3,
  FRI: 4,
  SAT: 5,
  SUN: 6,
}

export const TaskDaysAll = [
  TasksDaysTypes.MON,
  TasksDaysTypes.TUE,
  TasksDaysTypes.WEN,
  TasksDaysTypes.THU,
  TasksDaysTypes.FRI,
  TasksDaysTypes.SAT,
  TasksDaysTypes.SUN
]

export const TasksTypesModel = [
  {
    id: TasksTypes.MEDICINE,
    title: 'Лекарство',
  },
  {
    id: TasksTypes.EXERCISES,
    title: 'Упражнение',
  },
  {
    id: TasksTypes.ANALYSES,
    title: 'Анализ',
  },
  {
    id: TasksTypes.PROCEDURE,
    title: 'Процедура',
  },
  {
    id: TasksTypes.CONSULTATION,
    title: 'Консультация',
  },
  {
    id: TasksTypes.MANIPULATION,
    title: 'Манипуляция',
  },
  // UM-1496: Скрыть "Исследования" и "Доп. задания" на Проде
  {
    id: TasksTypes.RESEARCHES,
    title: 'Исследование',
  },
  {
    id: TasksTypes.EXTRATASKS,
    title: 'Дополнительное задание',
  },
  // hide articles
  ...(process.env.REACT_APP_IS_PROD !== 'true' ? [
    {
      id: TasksTypes.ARTICLE,
      title: 'Статья',
    }] : []),
  /* {
    id: TasksTypes.EVENT,
    title: 'Событие',
  }, */
]

export const TasksTypesModalAll = [
  {
    id: TasksTypes.ALL,
    title: 'Выбрать все',
  },
  ...TasksTypesModel
]

export const TasksTypesModelAdmin = [
  {
    id: TasksTypes.CONSULTATION,
    title: 'Консультация',
  },
]

export const TasksTypesModelArticles = [
  {
    id: TasksTypes.ARTICLE,
    title: 'Статья',
  },
]

export const TaskSourceTypesModel = [
  {
    id: TaskSourceTypes.STANDALONE,
    title: TaskSourceTypesName[TaskSourceTypes.STANDALONE],
  },
  {
    id: TaskSourceTypes.COURSE,
    title: TaskSourceTypesName[TaskSourceTypes.COURSE],
  },
  {
    id: TaskSourceTypes.SERIES,
    title: TaskSourceTypesName[TaskSourceTypes.SERIES],
  },
]

export const TasksDaysTypesModel = [
  {
    id: TasksDaysTypes.EVERYDAY,
    title: 'Каждый день',
    isSingle: true,
  },
  {
    id: TasksDaysTypes.MON,
    title: 'Пн',
  },
  {
    id: TasksDaysTypes.TUE,
    title: 'Вт',
  },
  {
    id: TasksDaysTypes.WEN,
    title: 'Ср',
  },
  {
    id: TasksDaysTypes.THU,
    title: 'Чт',
  },
  {
    id: TasksDaysTypes.FRI,
    title: 'Пт',
  },
  {
    id: TasksDaysTypes.SAT,
    title: 'Сб',
  },
  {
    id: TasksDaysTypes.SUN,
    title: 'Вс',
  },
]

export const TaskTimeVariants = {
  MORNING: {
    id: 'morning',
    title: 'Утро',
    time: '07:00',
  },
  AFTERNOON: {
    id: 'afternoon',
    title: 'День',
    time: '15:00',
  },
  EVENING: {
    id: 'evening',
    title: 'Вечер',
    time: '23:00',
  }
}
